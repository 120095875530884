exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-leaded-glass-js": () => import("./../../../src/pages/customLeadedGlass.js" /* webpackChunkName: "component---src-pages-custom-leaded-glass-js" */),
  "component---src-pages-front-door-insulated-repair-js": () => import("./../../../src/pages/frontDoorInsulatedRepair.js" /* webpackChunkName: "component---src-pages-front-door-insulated-repair-js" */),
  "component---src-pages-front-door-leaded-repair-js": () => import("./../../../src/pages/frontDoorLeadedRepair.js" /* webpackChunkName: "component---src-pages-front-door-leaded-repair-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insulated-single-pane-repair-js": () => import("./../../../src/pages/insulatedSinglePaneRepair.js" /* webpackChunkName: "component---src-pages-insulated-single-pane-repair-js" */)
}

